import React from 'react';
import { Route } from 'cccisd-laravel-boilerplate';
import defaultProps from 'recompose/defaultProps.js';
import { Switch } from 'react-router-dom';
import {
    Dashboard as AppdefDashboard,
    Manage as AppdefManage,
    Help,
    AdminRoutes,
    Logs as AppdefLogs,
} from 'cccisd-laravel-appdefs';
import { Quest, ViewData } from 'cccisd-laravel-assignment';
import { ResourceCenter } from 'cccisd-laravel-resources';

// Layouts
import MainLayout from './layouts/MainLayout';
import FluidLayout from './layouts/FluidLayout';
import FixedLayout from './layouts/FixedLayout';
import DemoLayout from './layouts/DemoLayout';
// Pages
/* eslint-disable import/no-extraneous-dependencies, import/no-unresolved */
import Welcome from './pages/Welcome'; // Include it into main bundle
import SignUp from 'bundle-loader?lazy!./pages/SignUp';
import AcceptCoaching from 'bundle-loader?lazy!./pages/AcceptCoaching';
import CheckUpFeedbackCoach from 'bundle-loader?lazy!./pages/CheckUpFeedbackCoach';
import CheckUpFeedbackTeacher from 'bundle-loader?lazy!./pages/CheckUpFeedbackTeacher';
import MyTeachers from 'bundle-loader?lazy!./pages/MyTeachers';
import TeacherHome from 'bundle-loader?lazy!./pages/TeacherHome';
import EditSiteContent from 'bundle-loader?lazy!./pages/EditSiteContent';
import ObservationalPractice from 'bundle-loader?lazy!./pages/ObservationalPractice';
import ExportData from 'bundle-loader?lazy!./pages/ExportData';
import SecureMessagingTeacher from './pages/SecureMessaging/SecureMessagingTeacher';
import SecureMessagingCoach from './pages/SecureMessaging/SecureMessagingCoach';

// Components
import AuthRedirect from 'bundle-loader?lazy!./components/AuthRedirect';

// Laravel packages
import Nexus from 'bundle-loader?lazy!cccisd-laravel-nexus';

// Bind MainLayout by default
const AppRoute = defaultProps({ layout: DemoLayout })(Route);
const MainLayoutFluid = defaultProps({ className: 'container-fluid' })(MainLayout);

const AppDefinitions = window.cccisd.appDefs;
const Fortress = window.cccisd.fortress;
const actingRole = Fortress.user.acting.data_type;
const isNotAdmin = actingRole === 'coach' || actingRole === 'teacher';
const match = {
    AppdefDashboard,
    AppdefManage,
    ResourceCenter,
    FluidLayout,
    FixedLayout,
    DemoLayout,
    SignUp,
    Welcome,
    MyTeachers,
    AcceptCoaching,
    Help,
    TeacherHome,
    CheckUpFeedbackCoach,
    CheckUpFeedbackTeacher,
    EditSiteContent,
    ObservationalPractice,
    ExportData,
    ViewData,
    SecureMessagingTeacher,
    SecureMessagingCoach,
};

const layoutMatch = { FluidLayout: DemoLayout };

export default () => (
    <Switch>
        {AppDefinitions.routes.map(route => {
            const Component = match[route.componentHandle];
            if (!Component) {
                return null;
            }
            if (route.url === '/manage' || route.url === '/data') {
                const userRole = Fortress.user.acting.role.handle;
                const uberCoach = Fortress.user.acting.role_data.ubercoach === 1;
                const canManage = userRole === 'uberadmin' || uberCoach;

                if (canManage) {
                    return (
                        <AppRoute
                            key={route.handle}
                            path={route.url}
                            component={Component}
                            componentProps={route.componentProps}
                            exact
                            layout={'layout' in route ? layoutMatch[route.layout] : MainLayoutFluid}
                        />
                    );
                }
                if (!canManage) {
                    return (
                        <AppRoute
                            key={route.handle}
                            path={Fortress.settings.config.after_login_url}
                            component={AuthRedirect}
                            exact
                        />
                    );
                }
            }

            return (
                <AppRoute
                    key={route.handle}
                    path={route.url}
                    component={Component}
                    componentProps={route.componentProps}
                    exact
                    layout={'layout' in route ? layoutMatch[route.layout] : MainLayoutFluid}
                />
            );
        })}

        {Fortress.auth() && <AppRoute path="/" component={AuthRedirect} exact />}
        {Fortress.auth() && (Fortress.user.acting.role_data.ubercoach === 1 || Fortress.isSuperUser()) && (
            <AppRoute path="/appdefs/logs" component={AppdefLogs} />
        )}
        {Fortress.auth() && (Fortress.user.acting.role_data.ubercoach || Fortress.isSuperUser()) && (
            <AppRoute path="/siteContent" component={EditSiteContent} />
        )}
        {Fortress.auth() && (Fortress.user.acting.role_data.ubercoach || Fortress.isSuperUser()) && (
            <AppRoute path="/exportData" component={ExportData} />
        )}

        <AppRoute path="/acceptance/:teacherHash?/:coachHash?" component={AcceptCoaching} />

        <AppRoute path="/signUp/:pathName?" component={SignUp} />

        <AppRoute path="/account/login" component={SignUp} />
        <AppRoute path="/help/:helpCenter?" component={Help} layout={DemoLayout} />
        {/* Laravel packages routes */}

        <AppRoute path={['/quest/preview', '/d', '/survey']} component={Quest} layout={DemoLayout} />

        {Fortress.auth() && (
            <AppRoute
                path="/observation/:deploymentHandle"
                component={ObservationalPractice}
                componentProps={{
                    errorLayout: MainLayout,
                }}
                layout={DemoLayout}
            />
        )}
        {Fortress.auth() && isNotAdmin && (
            <AppRoute
                path="/PD/:deploymentHandle"
                component={ObservationalPractice}
                componentProps={{
                    errorLayout: MainLayout,
                }}
                layout={DemoLayout}
            />
        )}
        {Fortress.auth() && <AppRoute path="/resource" component={ResourceCenter} handle="default" />}

        {Fortress.auth() &&
            ['uberadmin', 'questprojectadmin'].includes(Fortress.user.acting.role.handle) &&
            AdminRoutes(DemoLayout)}

        <AppRoute path="/account" component={Nexus} />

        <AppRoute path={Fortress.settings.config.after_login_url} component={AuthRedirect} exact />

        {/* Not found page */}
        <AppRoute component={AuthRedirect} componentProps={{ isComponentOnly: true }} />
    </Switch>
);
